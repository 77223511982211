import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Button, Typography, CircularProgress } from '@mui/material';
import { TASK_URL } from '../api';
import useToken from '../../useToken';
import TaskDetails from './Status/TaskDetails';

function DataTable() {
  const [data, setData] = useState([]);
  const { token } = useToken();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(TASK_URL, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token?.access_token}` }
    })
      .then((response) => response.json())
      .then((jsonData) => {
        setData(Array.isArray(jsonData) ? jsonData : [jsonData]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setLoading(false);
      });
  }, [token]);
  const onDeleteRow = (rowIndex) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this task?');

    if (isConfirmed) {
      fetch(TASK_URL + `${data[rowIndex]?.id}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token?.access_token}` }
      }).then((response) => {
        if (response.ok) {
          const updatedData = [...data];
          updatedData.splice(rowIndex, 1);
          setData(updatedData);
        } else {
          console.error('Deletion error');
        }
      });
    }
  };

  const onViewDetail = (rowIndex) => {
    const taskId = data[rowIndex]?.id;
    // Toggle: if the same task is clicked again, set to null; otherwise, set the new task ID
    setSelectedTaskId((prevId) => (prevId === taskId ? null : taskId));
  };

  const onEditRow = (rowIndex) => {
    window.alert('This functionality is not implemented yet!');
  }

  function formatString(inputString) {
    return inputString.replace(/_/g, ' ').replace(/\b\w/g, match => match.toUpperCase());
  }

  // Check if 'data' is an array
  if (!Array.isArray(data)) {
    // Handle the case where 'data' is not an array
    return <div>Data is not available or not in the expected format.</div>;
  }
  const columns = data.length > 0 ? ['id', 'title', 'description'] : [];

  const onBackClick = () => {
    // Set selectedTaskId to null or perform any other logic to go back
    setSelectedTaskId(null);
  };

  return (
    <Paper elevation={3} style={{ padding: '30px', margin: '10px', borderRadius: '15px', paddingBottom: '60px' }}>
      <Typography variant="h4" align="center" gutterBottom style={{ padding: '10px' }}>
        Active Models
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : selectedTaskId ? (
        <TaskDetails taskId={selectedTaskId} onBackClick={onBackClick} />
      ) : (
        <>
          <Table style={{ fontWeight: 'bold' }}>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} style={{ fontWeight: 'bold' }}>
                    {formatString(column)}
                  </TableCell>
                ))}
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex} style={{}}>
                  {columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex} >
                      {row[column]}
                    </TableCell>
                  ))}
                  <TableCell style={{}}>
                    <Button onClick={() => onViewDetail(rowIndex)}>
                      <InfoIcon />
                    </Button>
                    <Button onClick={() => onEditRow(rowIndex)}>
                      <EditIcon />
                    </Button>
                    <Button onClick={() => onDeleteRow(rowIndex)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Paper>
  );
}

export default DataTable;
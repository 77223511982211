import React, { useEffect, useState } from 'react';
import { TableContainer, Paper, CircularProgress, Typography, Table, TableBody, TableRow, TableCell, Grid } from '@mui/material';
import { TASK_URL } from '../../api';
import useToken from '../../../useToken';
import Chart from './Chart';
import './TaskDetails.css';
import { UndoOutlined } from '@mui/icons-material';

// const exog_tmp = [
//   'week_day', 'hour_day', 'Holiday_next_day', 'visibility',
//   'windBearing', 'temperature', 'dewPoint', 'pressure',
//   'apparentTemperature', 'windSpeed', 'humidity', 'month']

function TaskDetails({ taskId , onBackClick}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { token } = useToken();
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!taskId) return;
        const response = await fetch(TASK_URL + `${taskId}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token?.access_token}` }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }

        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [taskId, token]);

  const toggleReload = () => {
    setReload(!reload);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {data.title}  <UndoOutlined onClick={onBackClick} style={{ cursor: 'pointer', paddingLeft: '10px' }} 
    color="primary"  />
      </Typography>
      <Typography variant="body1" gutterBottom style={{ paddingBottom: '20px' }}>{data.description}</Typography>

      <Grid container spacing={2} style={{ marginBottom: "70px" }}>

        {/* First MUI table title could be Status */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '15px', margin: '10px',height:'100%', alignItems: "center", justifyContent:'space-between', borderRadius: '15px' }}>
            <Typography variant="h6" gutterBottom>Status</Typography>
            <TableContainer className="tableContainer">
              <Table style={{ width: '100%'}}>
                <TableBody>
                  <TableRow>
                    <TableCell>Forecast Error:</TableCell>
                    <TableCell>{data?.forecast_error || '4.5%'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Latest Observation:</TableCell>
                    <TableCell>{data?.latest_observation || '2024-02-15 10:00:00'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Forecasted Until:</TableCell>
                    <TableCell>{data?.forecasted_until || '2024-02-16 10:00:00'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Forecast Trend:</TableCell>
                    <TableCell>{data?.forecast_trend || 'Unknown'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Second MUI table title could be Info */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '15px', margin: '10px',height:'100%', alignItems: "center", justifyContent:'space-between' , borderRadius: '15px' }}>
            <Typography variant="h6" gutterBottom>Info</Typography>
            <TableContainer className="tableContainer">
              <Table style={{ width: '100%' }}>
                <TableBody>
                  <TableRow>
                    <TableCell>Frequency:</TableCell>
                    <TableCell>{data?.frequency || 'Hourly'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Forecast Horizon:</TableCell>
                    <TableCell>{data?.horizon || '24 Hours'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Storage Source:</TableCell>
                    <TableCell>{data.storage_source}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Streaming Source:</TableCell>
                    <TableCell>{data.streaming_source}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Streaming Destination:</TableCell>
                    <TableCell>{data.streaming_destination}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* Chart */}
          <Chart taskId={taskId} reload={reload} toggleReload={toggleReload} style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </>
  );
}

export default TaskDetails;